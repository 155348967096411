import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import React, { useContext } from 'react';

import { tw } from '../../utils/tailwind';
import { Box, Flex } from '../Core';
import IconWithText from '../IconWithText';
import Image, { IImage } from '../Image/Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import Flags, { Languages } from '../Flags';
import ThemedButton from '../ThemedButton';
import LanguageContext from '../../context/LanguageContext';
import { Link } from 'gatsby';

interface ITeamMemberProps {
  image: IImage | null;
  firstName: string;
  gsm?: string;
  lastName: string;
  position?: string;
  mail?: string;
  description?: string;
  tag?: string;
  languages: Readonly<string[]>;
  linkType?: string;
  textLink?: string;
  externalLink?: string;
  phoneLink?: string;
  leaderSpeciality?: string;
  leaderCenter?: string;
  showLink?: boolean;
  hideDescription?: boolean;
  slug?: string;
}

const TeamMember: FCWithClassName<ITeamMemberProps> = (props) => {
  const translations = {
    fr: {
      appointment: 'Prendre rendez vous',
    },
    en: {
      appointment: 'Make an appointment',
    },
  };

  const {
    currentLanguage: { slug: currLang },
  } = useContext(LanguageContext);

  const {
    className,
    description,
    tag,
    firstName,
    gsm,
    image,
    lastName,
    mail,
    position,
    languages,
    linkType,
    textLink,
    externalLink,
    phoneLink,
    leaderCenter,
    leaderSpeciality,
    showLink = true,
    hideDescription = false,
    slug,
    ...rest
  } = props;

  //console.log(slug);

  return (
    <Flex
      direction="column"
      className={tw(
        'max-w-xs sm:max-w-sm xl:max-w-[260px] xxl:max-w-xs z-10 bg-primary h-fit relative w-full group',
        className
      )}
      {...rest}
    >
      <Box
        className={tw(
          'bg-primaryMain h-full absolute -right-4 lg:right-[-10%] top-2.5 lg:top-8 w-full z-[-1]',
          showLink &&
            'group-hover:-translate-y-2 group-hover:bg-primary-600 transition'
        )}
      />
      <div className="relative max-w-[200px]">
        <Image image={image} className="max-w-[200px] h-full" />

        {languages && (
          <ul className="relative z-10 grid grid-cols-4 gap-2 pl-6 -mt-2 list-none lg:pl-10">
            {languages.map((language: Languages) => (
              <li key={`user-${lastName}-${language}`}>
                <Flags lang={language} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <Text variant={TEXT_STYLES.H3} className="!text-[32px] font-black pt-8">
        {`${firstName} ${lastName}`}
      </Text>

      <Text variant={TEXT_STYLES.H4} className="!text-[32px] font-medium pt-8">
        {position}
      </Text>

      {leaderSpeciality && (
        <div
          className="self-start px-2 py-1 font-bold bg-yellow-400 rounded-lg"
          style={{
            boxShadow:
              'rgba(149, 157, 165, 0.2) 0px 6px 4px -1px, 0px 1px 3px -1px',
          }}
        >
          <Text
            variant={TEXT_STYLES.P}
            className="text-xs font-bold text-center uppercase"
          >
            {leaderSpeciality}
          </Text>
        </div>
      )}

      {leaderCenter && (
        <div
          className="self-start px-2 py-1 mt-2 font-bold bg-[#1c6d7d] text-white rounded-lg"
          style={{
            boxShadow:
              'rgba(149, 157, 165, 0.2) 0px 6px 4px -1px, 0px 1px 3px -1px',
          }}
        >
          <Text
            variant={TEXT_STYLES.P}
            className="text-xs font-bold text-center uppercase"
          >
            {leaderCenter}
          </Text>
        </div>
      )}

      {tag && (
        <div
          className="self-start px-2 py-1 mt-2 font-bold bg-[#6dde89] rounded-lg"
          style={{
            boxShadow:
              'rgba(149, 157, 165, 0.2) 0px 6px 4px -1px, 0px 1px 3px -1px',
          }}
        >
          <Text
            variant={TEXT_STYLES.P}
            className="text-xs font-bold text-center uppercase"
          >
            {tag}
          </Text>
        </div>
      )}

      {!hideDescription && (
        <Text variant={TEXT_STYLES.P} className="lg:!text-xl pt-6 w-[105%]">
          {description}
        </Text>
      )}
      <address className="mt-4">
        {mail && (
          <StyledLink
            shouldObfuscate
            isEmail
            to={mail}
            className="relative z-20 text-text hover:underline"
          >
            <IconWithText
              description={mail}
              icon={FiMail}
              iconClassName="w-8 h-8"
              isInline
            />
          </StyledLink>
        )}
        {gsm && (
          <StyledLink
            shouldObfuscate
            isPhone
            to={gsm}
            className="relative z-20 text-text hover:underline"
          >
            <IconWithText
              description={gsm}
              icon={FiPhone}
              iconClassName="w-8 h-8"
              isInline
            />
          </StyledLink>
        )}
      </address>
      {mail && (
        <StyledLink shouldObfuscate isEmail to={mail} className="text-text">
          <FiMail className="z-20 absolute -right-1.5 top-10 w-10 sm:w-12 h-auto hover:scale-110 transition" />
        </StyledLink>
      )}

      {(phoneLink || externalLink) && (
        <StyledLink
          isPhone={linkType === 'phone'}
          shouldObfuscate={linkType === 'phone'}
          target="_blank"
          to={linkType === 'phone' ? phoneLink : undefined}
          href={linkType === 'link' ? externalLink : undefined}
          className="relative z-20 ml-8 mx-auto mt-6 lg:ml-[10%] w-full text-center !h-auto !pb-0 px-2"
        >
          <ThemedButton className="!h-auto lg:!h-auto hover:bg-white transition">
            {textLink ?? translations[currLang ?? 'fr']?.appointment}
          </ThemedButton>
        </StyledLink>
      )}

      <Link to={slug} className="absolute inset-0 no-underline border-none">
        <span className="sr-only">
          {firstName} {lastName}
        </span>
      </Link>
    </Flex>
  );
};

export default TeamMember;
