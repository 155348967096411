import { PageProps, graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';
import SectionWithTitle from '../../components/SectionWithTitle';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import { Grid, Stack } from '../../components/Core';
import TeamMember from '../../components/TeamMember';
import Select from '../../components/Form/Select';
import { IWordpressStaff } from '../../types/wordpress/staff';
import { IWordpressCentre } from '../../types/wordpress/centre';
import { __ } from '../../utils/helpers/i18n';

const STATIC_STRINGS = {
  en: {
    filters: 'Select a specialty',
    centers: 'Select a center',
    title: 'Our staff',
    medicalTitle: 'Medical team',
    adminTitle: 'Admin team',
    centerLabel: 'Center',
    specialtyLabel: 'Specialty',
    filterLabel: 'Filters',
  },
  fr: {
    filters: 'Choisissez une spécialité',
    centers: 'Choisissez un centre',
    title: 'Notre équipe',
    medicalTitle: 'Équipe médicale',
    adminTitle: 'Équipe admin',
    centerLabel: 'Centre',
    specialtyLabel: 'Spécialité',
    filterLabel: 'Filtres',
  },
};

type ArchiveStaffProps = PageProps<Queries.ArchiveStaffQuery, IPageContext>;

interface Specialty {
  title: string;
  slug: string;
}

const filterStaff = (
  staffs: IWordpressStaff[],
  center: IWordpressCentre,
  specialty: string,
  isAdmin: boolean
) => {
  return staffs
    .filter((staff) => {
      if (specialty && !staff.staffInformations?.poste) return false;

      let shouldBeVisible = true;

      // Check if staff has the selected specialty
      if (specialty) {
        const hasSpecialty = staff.staffInformations?.poste?.some(
          (poste: any) => poste?.slug === specialty
        );

        if (!hasSpecialty) shouldBeVisible = false;
      }

      if (center) {
        // Check if staff is in the selected center
        const isInCenter = center.servicesEtStaff.staff.some(
          (s) => s.id === staff.id
        );

        if (!isInCenter) shouldBeVisible = false;
      }

      if (shouldBeVisible) {
        return isAdmin
          ? staff.staffTypes?.nodes.some((t) => t.slug === 'admin')
          : staff.staffTypes?.nodes.some((t) => t.slug === 'medical');
      }

      return false;
    })
    .filter((staff, index, self) => {
      // Remove duplicates
      return (
        index ===
        self.findIndex(
          (t) =>
            t?.staffInformations?.prenom === staff?.staffInformations?.prenom
        )
      );
    });
};

const ArchiveStaff = ({ pageContext, data, location }: ArchiveStaffProps) => {
  const [selectedCentre, setSelectedCentre] =
    React.useState<IWordpressCentre>();
  const [selectedSpecialty, setSelectedSpecialty] = React.useState<string>();

  if (!pageContext.defaultLanguage) return null;

  const allSpecialties = data.allWpStaff.nodes
    .reduce((acc, staff) => {
      return acc.concat(staff.staffInformations?.poste?.map((poste) => poste));
    }, [] as Specialty[])
    // Remove duplicates
    .filter((specialty, index, self) => {
      if (!specialty || !specialty.title) return false;

      return (
        index ===
        self.findIndex((t) => t?.title && t.title === specialty?.title)
      );
    });

  const allCenters = data.allWpCentre.nodes;

  const lang = pageContext.currentLanguage as any;

  return (
    <Layout
      currentLanguage={{
        code: pageContext.currentLanguageSlug.toUpperCase() as CountryCode,
        locale: pageContext.currentLanguageSlug === 'fr' ? 'fr_BE' : 'en_GB',
        slug: pageContext.currentLanguageSlug,
      }}
      currentPath={location.pathname}
      menuData={pageContext.menus}
      siteSettings={{ ...pageContext.siteSettings, showLanguageSelector: true }}
      submenuItems={pageContext.submenuItems}
      translations={[
        pageContext.currentLanguageSlug === 'fr'
          ? {
              language: { code: 'EN', locale: 'en_GB' },
              uri: '/en/staff',
            }
          : { language: { code: 'FR', locale: 'fr_BE' }, uri: '/staff' },
      ]}
    >
      <SEO
        pageData={{
          language: {
            locale:
              pageContext.currentLanguageSlug === 'fr' ? 'fr_BE' : 'en_GB',
            slug: pageContext.currentLanguageSlug,
          },
          link: location.pathname,
          seo: {
            title:
              STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
                ?.title + ' -  Uperform',
            metaRobotsNoindex: 'index',
            metaRobotsNofollow: 'follow',
            canonical: location.pathname,
            cornerstone: false,
            focuskw: '',
            metaDesc: '',
            metaKeywords: '',
            opengraphDescription: '',
            opengraphTitle: '',
            twitterDescription: '',
            twitterTitle: '',
            twitterImage: null,
            opengraphImage: null,
            schema: undefined,
          },
          translations: [],
        }}
      />
      <div>
        <Text
          variant={TEXT_STYLES.H1}
          className="block mx-auto mt-20 mb-4 text-center"
        >
          {
            STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
              ?.title
          }
        </Text>

        <Text variant={TEXT_STYLES.H2} className="block mt-20 mb-4 text-left">
          {
            STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
              ?.medicalTitle
          }
        </Text>

        <div className="flex flex-col flex-wrap gap-4 mb-2 sm:items-end sm:justify-start sm:flex-row">
          <div>
            <Text variant={TEXT_STYLES.P} className="text-left">
              {
                STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
                  ?.filterLabel
              }
            </Text>
            <Select
              className="text-base !mb-[0.5rem] min-w-[200px]"
              type="select"
              name="centre"
              id="centre"
              placeholder={
                STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
                  ?.centers
              }
              onChange={(e) => {
                e.preventDefault();

                setSelectedCentre(
                  (allCenters.find(
                    (centre) => centre.slug === e.target.value
                  ) as IWordpressCentre) || undefined
                );
              }}
              value={selectedCentre ? selectedCentre.slug : undefined}
              options={allCenters.map((centre) => {
                if (!centre) return undefined;

                return {
                  checked: false,
                  label: centre.title,
                  name: centre.slug,
                };
              })}
            />
          </div>
          <div>
            <Select
              className="text-base !mb-[0.5rem] min-w-[200px]"
              type="select"
              name="centre"
              id="centre"
              placeholder={
                STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
                  ?.filters
              }
              onChange={(e) => {
                e.preventDefault();

                setSelectedSpecialty(e.target.value);
              }}
              value={selectedSpecialty}
              options={allSpecialties.map((specialty) => {
                if (!specialty) return undefined;

                return {
                  checked: false,
                  label: specialty.title,
                  name: specialty.slug,
                };
              })}
            />
          </div>

          {(selectedCentre || selectedSpecialty) && (
            <div className="self-end mb-[0.5rem]">
              <button
                className="flex items-center self-end w-auto gap-1 pt-2 pb-1 pl-2 pr-3 text-left transition bg-gray-700 hover:bg-gray-700/50"
                type="button"
                onClick={() => {
                  // Reset state with taking into account the default values
                  setSelectedCentre(undefined);
                  setSelectedSpecialty(undefined);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 w-5 h-5 -mt-1 text-primary-500"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256l105.3-105.4z"
                    fill="currentColor"
                  />
                </svg>
                <span className="font-bold text-white uppercase font-heading">
                  {__('Réinitialiser', lang)}
                </span>
              </button>
            </div>
          )}
        </div>

        <div>
          <Text variant={TEXT_STYLES.P} className="block mt-8 mb-4 text-left">
            {`
              ${
                filterStaff(
                  (data.allWpStaff.nodes as any[]) || [],
                  selectedCentre,
                  selectedSpecialty,
                  false
                ).length + ' '
              }
              résultats`}
          </Text>
        </div>
        <Grid className="mb-8 justify-center w-full gap-x-4 xl:gap-x-12 gap-y-12 grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]">
          {filterStaff(
            (data.allWpStaff.nodes as any[]) || [],
            selectedCentre,
            selectedSpecialty,
            false
          )?.map((staffMember: any) => {
            if (!staffMember) return null;
            const image = staffMember.staffInformations.photo;

            //console.log(staffMember);

            return (
              <TeamMember
                key={staffMember.id}
                {...staffMember.staffInformations}
                image={serializeImageForImageComponent({ image })}
                firstName={staffMember.staffInformations.prenom}
                lastName={staffMember.staffInformations.nom}
                mail={staffMember.staffInformations.email}
                className="max-w-lg"
                leaderCenter={staffMember.staffSpecialities?.nodes?.[0]?.name}
                leaderSpeciality={staffMember.staffLeadPlaces?.nodes?.[0]?.name}
                position={staffMember.staffInformations.poste?.[0]?.title}
                showLink={true}
                hideDescription={true}
                slug={staffMember.uri.replace('equipe', 'staff')}
              />
            );
          })}
        </Grid>

        <Text variant={TEXT_STYLES.H2} className="block mt-40 mb-4 text-left">
          {
            STATIC_STRINGS[pageContext.currentLanguageSlug as 'fr' | 'en']
              ?.adminTitle
          }
        </Text>

        <Grid className="mb-8 justify-center w-full gap-x-4 xl:gap-x-12 gap-y-12 grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]">
          {filterStaff(
            (data.allWpStaff.nodes as any[]) || [],
            undefined,
            '',
            true
          )?.map((staffMember: any) => {
            if (!staffMember) return null;
            const image = staffMember.staffInformations.photo;
            return (
              <TeamMember
                key={staffMember.id}
                {...staffMember.staffInformations}
                image={serializeImageForImageComponent({ image })}
                firstName={staffMember.staffInformations.prenom}
                lastName={staffMember.staffInformations.nom}
                mail={staffMember.staffInformations.email}
                tag={staffMember.staffInformations.tag}
                position={staffMember.staffInformations.poste?.[0]?.title}
                className="max-w-lg"
              />
            );
          })}
        </Grid>
      </div>
    </Layout>
  );
};

export default ArchiveStaff;

export const query = graphql`
  query ArchiveStaff($currentLanguageSlug: String) {
    allWpStaff(
      limit: 10000
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        ...TeamMemberFragment
      }
    }
    allWpConfiguration {
      nodes {
        configuration {
          textes {
            voirPlus
          }
        }
      }
    }
    allWpCentre(
      limit: 100000
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        title
        slug
        servicesEtStaff {
          staff {
            ... on WpStaff {
              id
            }
          }
        }
      }
    }
  }
`;
